// プロジェクト用グローバルスタイル
// import Nebular Theme System and the default theme
@import "~@nebular/theme/styles/theming";
@import "~@nebular/theme/styles/themes/default";
@import "~@nebular/theme/styles/themes/corporate";
@import "~@nebular/theme/styles/themes/cosmic";
@import "~@nebular/theme/styles/themes/dark";
@import "../@theme/styles/material/material-light";
@import "../@theme/styles/material/material-dark";

// nebularのテーマスタイルを上書き
// https://akveo.github.io/nebular/docs/design-system/enable-customizable-theme#modify-theme-variables
// @see https://colors.eva.design/?utm_campaign=eva_design%20-%20eva%20colors%20-%20nebular%20docs%20link&utm_source=nebular&utm_medium=referral&utm_content=enable_customizable_theme_primary_color&__hstc=251808470.d2995e4542b67974d33bb8ffaa1a52db.1605107556602.1605783925446.1605787944552.10&__hssc=251808470.2.1605787944552&__hsfp=2005308689

$nb-themes: nb-register-theme(
  (
    // カラーマップ
    color-primary-100: #dbf9c8,
    color-primary-200: #b0f494,
    color-primary-300: #77de5b,
    color-primary-400: #44be32,
    color-primary-500: #099403,
    color-primary-600: #027f07,
    color-primary-700: #016a0e,
    color-primary-800: #005512,
    color-primary-900: #004715,
    color-primary-transparent-100: rgba(9, 148, 3, 0.08),
    color-primary-transparent-200: rgba(9, 148, 3, 0.16),
    color-primary-transparent-300: rgba(9, 148, 3, 0.24),
    color-primary-transparent-400: rgba(9, 148, 3, 0.32),
    color-primary-transparent-500: rgba(9, 148, 3, 0.4),
    color-primary-transparent-600: rgba(9, 148, 3, 0.48),
    color-success-100: #ddfbd5,
    color-success-200: #b5f8ad,
    color-success-300: #81ea81,
    color-success-400: #5dd668,
    color-success-500: #2fbc49,
    color-success-600: #22a145,
    color-success-700: #178741,
    color-success-800: #0e6d3b,
    color-success-900: #095a36,
    color-success-transparent-100: rgba(47, 188, 73, 0.08),
    color-success-transparent-200: rgba(47, 188, 73, 0.16),
    color-success-transparent-300: rgba(47, 188, 73, 0.24),
    color-success-transparent-400: rgba(47, 188, 73, 0.32),
    color-success-transparent-500: rgba(47, 188, 73, 0.4),
    color-success-transparent-600: rgba(47, 188, 73, 0.48),
    color-info-100: #cbf2fc,
    color-info-200: #99e0f9,
    color-info-300: #65c3ef,
    color-info-400: #3ea3e0,
    color-info-500: #0877cc,
    color-info-600: #055caf,
    color-info-700: #044492,
    color-info-800: #023076,
    color-info-900: #012261,
    color-info-transparent-100: rgba(8, 119, 204, 0.08),
    color-info-transparent-200: rgba(8, 119, 204, 0.16),
    color-info-transparent-300: rgba(8, 119, 204, 0.24),
    color-info-transparent-400: rgba(8, 119, 204, 0.32),
    color-info-transparent-500: rgba(8, 119, 204, 0.4),
    color-info-transparent-600: rgba(8, 119, 204, 0.48),
    color-warning-100: #fdf9ca,
    color-warning-200: #fcf297,
    color-warning-300: #f7e562,
    color-warning-400: #efd73b,
    color-warning-500: #e5c300,
    color-warning-600: #c4a400,
    color-warning-700: #a48700,
    color-warning-800: #846a00,
    color-warning-900: #6d5600,
    color-warning-transparent-100: rgba(229, 195, 0, 0.08),
    color-warning-transparent-200: rgba(229, 195, 0, 0.16),
    color-warning-transparent-300: rgba(229, 195, 0, 0.24),
    color-warning-transparent-400: rgba(229, 195, 0, 0.32),
    color-warning-transparent-500: rgba(229, 195, 0, 0.4),
    color-warning-transparent-600: rgba(229, 195, 0, 0.48),
    color-danger-100: #fcd3d6,
    color-danger-200: #faa8b6,
    color-danger-300: #f17a9a,
    color-danger-400: #e4588b,
    color-danger-500: #d32677,
    color-danger-600: #b51b73,
    color-danger-700: #97136b,
    color-danger-800: #7a0c60,
    color-danger-900: #650758,
    color-danger-transparent-100: rgba(211, 38, 119, 0.08),
    color-danger-transparent-200: rgba(211, 38, 119, 0.16),
    color-danger-transparent-300: rgba(211, 38, 119, 0.24),
    color-danger-transparent-400: rgba(211, 38, 119, 0.32),
    color-danger-transparent-500: rgba(211, 38, 119, 0.4),
    color-danger-transparent-600: rgba(211, 38, 119, 0.48),
    header-background-color: color-primary-500,
    footer-background-color: color-primary-500,
    text-disabled-color: color-basic-800,
    // スクロールバー
    scrollbar-width: calc(0.3125rem * 3)
  ),
  material-light,
  material-light
);


// 上書きしたカラーマップを使用するため、個々のスタイル定義は以降に記述する

// 入力フィールド編集不可時
// $nb-enable-css-custom-properties: true; // <-- enable css custom properties

$nb-themes: nb-register-theme(
  (
    // your theme
    text-basic-color: color-basic-800,
    text-disabled-color: color-basic-800
  ),
  default,
  default
);

// 必須マーク
.required-mark {
  // テーマのカラーマップを参照
  // @see https://akveo.github.io/nebular/docs/design-system/use-theme-variables#use-theme-variable
  color: nb-theme(color-danger-500);
  font-size: large;
  font-weight: bold;
}

// トースター
nb-toast {
  // 改行文字有効化
  white-space: pre-wrap;
}

// テーブル
ng2-smart-table {
  // ここに書くとtableが左寄せになるのを回避可能
  // ただしpagerがスクロール領域内になる
  // display: block !important;
  // max-height: 50vh !important;
  table {
    // 高さ固定、縦スクロール表示
    display: block !important;
    max-height: 50vh !important;
    thead {
      tr.ng2-smart-titles {
        th {
          // ヘッダーのスクロール追跡
          position: sticky !important;
          top: -1px;
          z-index: 1;
          background: #FFFFFF;
        }
      }
      th {
        white-space: nowrap;
        // ここに書くとフィルター行もスクロール追跡になる
        // タイトル行とは重なって表示される
        // position: sticky !important;
        // top: -1px;
        // z-index: 101;
        // background: nb-theme(color-basic-100);
      }
    }
    tbody {
      tr {
        white-space: nowrap;
      }
    }
  }
}
